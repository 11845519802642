import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getLocationGroups as getApi,
    saveLocationGroups as saveApi,
    editLocationGroups as editApi
} from '../../helpers/';

// actions
import { locationGroupsApiResponseSuccess, locationGroupsApiResponseError } from './actions';

// constants
import { LocationGroupsActionTypes } from './constants';

type LocationGroups = {
    payload: {
        _id: string;
        groupName: string;
        position: number;
        isActive: string;
        data: object;
    };
    type: string;
};

function* getLocationGroups(): SagaIterator {
    try {
        const response = yield call(getApi, {});
        let transferLocationsList = response.data.results;
        yield put(locationGroupsApiResponseSuccess(LocationGroupsActionTypes.API_RESPONSE_LIST, transferLocationsList));
    } catch (error: any) {
        yield put(locationGroupsApiResponseError(LocationGroupsActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveLocationGroups({ payload: { data } }: LocationGroups): SagaIterator {
    try {
        const response = yield call(saveApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(locationGroupsApiResponseSuccess(LocationGroupsActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(locationGroupsApiResponseError(LocationGroupsActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(locationGroupsApiResponseError(LocationGroupsActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editLocationGroups({ payload: { data } }: LocationGroups): SagaIterator {
    try {
        const response = yield call(editApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(locationGroupsApiResponseSuccess(LocationGroupsActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(locationGroupsApiResponseError(LocationGroupsActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(locationGroupsApiResponseError(LocationGroupsActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchLocationGroups() {
    yield takeEvery(LocationGroupsActionTypes.API_RESPONSE_LIST, getLocationGroups);
}

export function* watchSaveLocationGroups() {
    yield takeEvery(LocationGroupsActionTypes.API_RESPONSE_SAVE, saveLocationGroups);
}

export function* watchEditLocationGroups() {
    yield takeEvery(LocationGroupsActionTypes.API_RESPONSE_EDIT, editLocationGroups);
}

function* locationGroupsSaga() {
    yield all([fork(watchLocationGroups), fork(watchSaveLocationGroups), fork(watchEditLocationGroups)]);
}

export default locationGroupsSaga;
