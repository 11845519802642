// constants
import { TransferRatesActionTypes } from './constants';

const INIT_STATE = {
    transferRatesList: [],
    loading: false,
};

type State = {
    loading?: boolean;
};

type TransferRatesActionType = {
    type:
    | TransferRatesActionTypes.API_RESPONSE_SUCCESS
    | TransferRatesActionTypes.API_RESPONSE_ERROR
    | TransferRatesActionTypes.API_RESPONSE_LIST
    | TransferRatesActionTypes.API_RESPONSE_SAVE
    | TransferRatesActionTypes.API_RESPONSE_EDIT
    | TransferRatesActionTypes.API_RESPONSE_CLEAR
    payload: {
        actionType?: string;
        data?: [];
        error?: string;
    };
};

const TransferRates = (state: State = INIT_STATE, action: TransferRatesActionType): any => {
    switch (action.type) {
        case TransferRatesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TransferRatesActionTypes.API_RESPONSE_LIST: {
                    return {
                        ...state,
                        transferRatesList: action.payload.data,
                        loading: false,
                    };
                }
                case TransferRatesActionTypes.API_RESPONSE_SAVE: {
                    return {
                        ...state,
                        transferRateSave: true,
                        loading: false,
                    };
                }
                case TransferRatesActionTypes.API_RESPONSE_EDIT: {
                    return {
                        ...state,
                        transferRateEdit: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case TransferRatesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TransferRatesActionTypes.API_RESPONSE_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TransferRatesActionTypes.API_RESPONSE_SAVE: {
                    return {
                        ...state,
                        transferRateSave: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TransferRatesActionTypes.API_RESPONSE_EDIT: {
                    return {
                        ...state,
                        transferRateEdit: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case TransferRatesActionTypes.API_RESPONSE_LIST:
            return { ...state, loading: true };
        case TransferRatesActionTypes.API_RESPONSE_SAVE:
            return { ...state, loading: true };
        case TransferRatesActionTypes.API_RESPONSE_EDIT:
            return { ...state, loading: true };
        case TransferRatesActionTypes.API_RESPONSE_CLEAR: {
            return {
                ...state,
                transferRateEdit: false,
                transferRateSave: false,
            };
        }
        default:
            return { ...state };
    }
}

export default TransferRates;