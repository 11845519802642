// constants
import { TransferRatesActionTypes } from './constants';

export type TransferRatesActionType = {
    type:
        | TransferRatesActionTypes.API_RESPONSE_SUCCESS
        | TransferRatesActionTypes.API_RESPONSE_ERROR
        | TransferRatesActionTypes.API_RESPONSE_LIST
        | TransferRatesActionTypes.API_RESPONSE_SAVE
        | TransferRatesActionTypes.API_RESPONSE_EDIT
        | TransferRatesActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type TransferRates = {
    _id: string;
    pickupFrom: object;
    destination: object;
    tripType: string;
    passengerCount: number;
    isActive: string;
    rate: number;
};

// common success
export const transferRateApiResponseSuccess = (actionType: string, data: TransferRates | {}): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const transferRateApiResponseError = (actionType: string, error: string): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTransferRates = (): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveTransferRates = (data: TransferRates | {}): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editTransferRates = (data: TransferRates | {}): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearTransferRates = (): TransferRatesActionType => ({
    type: TransferRatesActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});