import { APICore } from './apiCore';

const api = new APICore();

function getTransferRates(params: {}) {
    const baseUrl = '/transferRate/';
    return api.get(`${baseUrl}`, params);
}

function saveTransferRates(params: object) {
    const baseUrl = '/transferRate/';
    return api.create(`${baseUrl}`, params);
}

function editTransferRates(params: object) {
    const baseUrl = '/transferRate/';
    return api.update(`${baseUrl}`, params);
}

export { getTransferRates, saveTransferRates, editTransferRates };