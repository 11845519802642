import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getTourRates as getApi,
    saveTourRates as saveApi,
    editTourRates as editApi
} from '../../helpers/';

// actions
import { tourRateApiResponseSuccess, tourRateApiResponseError } from './actions';

// constants
import { TourRatesActionTypes } from './constants';

type TourRates = {
    payload: {
        _id: string;
        tourName: string;
        passengerCount: number;
        hours: string;
        rate: number;
        tripType: string;
        isActive: boolean;
        data: object;
    };
    type: string;
};

function* getTourRates(): SagaIterator {
    try {
        const response = yield call(getApi, {});
        let tourRatesList = response.data.results;
        yield put(tourRateApiResponseSuccess(TourRatesActionTypes.API_RESPONSE_LIST, tourRatesList));
    } catch (error: any) {
        yield put(tourRateApiResponseError(TourRatesActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveTourRates({ payload: { data } }: TourRates): SagaIterator {
    try {
        const response = yield call(saveApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(tourRateApiResponseSuccess(TourRatesActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(tourRateApiResponseError(TourRatesActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(tourRateApiResponseError(TourRatesActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editTourRates({ payload: { data } }: TourRates): SagaIterator {
    try {
        const response = yield call(editApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(tourRateApiResponseSuccess(TourRatesActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(tourRateApiResponseError(TourRatesActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(tourRateApiResponseError(TourRatesActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchTourRates() {
    yield takeEvery(TourRatesActionTypes.API_RESPONSE_LIST, getTourRates);
}

export function* watchSaveTourRates() {
    yield takeEvery(TourRatesActionTypes.API_RESPONSE_SAVE, saveTourRates);
}

export function* watchEditTourRates() {
    yield takeEvery(TourRatesActionTypes.API_RESPONSE_EDIT, editTourRates);
}

function* tourRatesSaga() {
    yield all([fork(watchTourRates), fork(watchSaveTourRates), fork(watchEditTourRates)]);
}

export default tourRatesSaga;
