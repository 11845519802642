// constants
import { LocationGroupsActionTypes } from './constants';

export type LocationGroupsActionType = {
    type:
        | LocationGroupsActionTypes.API_RESPONSE_SUCCESS
        | LocationGroupsActionTypes.API_RESPONSE_ERROR
        | LocationGroupsActionTypes.API_RESPONSE_LIST
        | LocationGroupsActionTypes.API_RESPONSE_SAVE
        | LocationGroupsActionTypes.API_RESPONSE_EDIT
        | LocationGroupsActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type LocationGroups = {
    _id: string;
    groupName: string;
    position: number;
    isActive: string;
};

// common success
export const locationGroupsApiResponseSuccess = (actionType: string, data: LocationGroups | {}): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const locationGroupsApiResponseError = (actionType: string, error: string): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getLocationGroups = (): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveLocationGroups = (data: LocationGroups | {}): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editLocationGroups = (data: LocationGroups | {}): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearLocationGroups = (): LocationGroupsActionType => ({
    type: LocationGroupsActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});