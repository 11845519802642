import { APICore } from './apiCore';

const api = new APICore();

function getTourRates(params: {}) {
    const baseUrl = '/tourRate/';
    return api.get(`${baseUrl}`, params);
}

function saveTourRates(params: object) {
    const baseUrl = '/tourRate/';
    return api.create(`${baseUrl}`, params);
}

function editTourRates(params: object) {
    const baseUrl = '/tourRate/';
    return api.update(`${baseUrl}`, params);
}

export { getTourRates, saveTourRates, editTourRates };