import { APICore } from './apiCore';

const api = new APICore();

function getUsers(params: {}) {
    const baseUrl = '/user/';
    return api.get(`${baseUrl}`, params);
}

function saveUser(params: object) {
    const baseUrl = '/user/';
    return api.create(`${baseUrl}`, params);
}

function editUser(params: object) {
    const baseUrl = '/user/';
    return api.update(`${baseUrl}`, params);
}

export { getUsers, saveUser, editUser };