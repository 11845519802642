import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getDrivers as getApi,
    saveDriver as saveApi,
    editDriver as editApi
} from '../../helpers/';

// actions
import { driverApiResponseSuccess, driverApiResponseError } from './actions';

// constants
import { DriversActionTypes } from './constants';

type Drivers = {
    payload: {
        _id: string;
        fullName: string;
        driverPhone: string;
        driverEmail: string;
        isSMS: boolean;
        isEmail: boolean;
        isActive: boolean;
        data: object;
    };
    type: string;
};

function* getDrivers(): SagaIterator {
    try {
        const response = yield call(getApi, {});
        let DriversList = response.data.results;
        yield put(driverApiResponseSuccess(DriversActionTypes.API_RESPONSE_LIST, DriversList));
    } catch (error: any) {
        yield put(driverApiResponseError(DriversActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveDriver({ payload: { data } }: Drivers): SagaIterator {
    try {
        const response = yield call(saveApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(driverApiResponseSuccess(DriversActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(driverApiResponseError(DriversActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(driverApiResponseError(DriversActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editDriver({ payload: { data } }: Drivers): SagaIterator {
    try {
        const response = yield call(editApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(driverApiResponseSuccess(DriversActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(driverApiResponseError(DriversActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(driverApiResponseError(DriversActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchDrivers() {
    yield takeEvery(DriversActionTypes.API_RESPONSE_LIST, getDrivers);
}

export function* watchSaveDriver() {
    yield takeEvery(DriversActionTypes.API_RESPONSE_SAVE, saveDriver);
}

export function* watchEditDriver() {
    yield takeEvery(DriversActionTypes.API_RESPONSE_EDIT, editDriver);
}

function* driverSaga() {
    yield all([fork(watchDrivers), fork(watchSaveDriver), fork(watchEditDriver)]);
}

export default driverSaga;
