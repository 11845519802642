import { APICore } from './apiCore';

const api = new APICore();

function getLocationGroups(params: {}) {
    const baseUrl = '/locationGroups/';
    return api.get(`${baseUrl}`, params);
}

function saveLocationGroups(params: object) {
    const baseUrl = '/locationGroups/';
    return api.create(`${baseUrl}`, params);
}

function editLocationGroups(params: object) {
    const baseUrl = '/locationGroups/';
    return api.update(`${baseUrl}`, params);
}

export { getLocationGroups, saveLocationGroups, editLocationGroups };