// constants
import { TourRatesActionTypes } from './constants';

export type TourRatesActionType = {
    type:
        | TourRatesActionTypes.API_RESPONSE_SUCCESS
        | TourRatesActionTypes.API_RESPONSE_ERROR
        | TourRatesActionTypes.API_RESPONSE_LIST
        | TourRatesActionTypes.API_RESPONSE_SAVE
        | TourRatesActionTypes.API_RESPONSE_EDIT
        | TourRatesActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type TourRates = {
    _id: string;
    tourName: string;
    peopleCount: string;
    hours: string;
    rate: number;
    tripType: string;
    isActive: boolean;
};

// common success
export const tourRateApiResponseSuccess = (actionType: string, data: TourRates | {}): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const tourRateApiResponseError = (actionType: string, error: string): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTourRates = (): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveTourRates = (data: TourRates | {}): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editTourRates = (data: TourRates | {}): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearTourRates = (): TourRatesActionType => ({
    type: TourRatesActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});