// constants
import { TransferLocationsActionTypes } from './constants';

export type TransferLocationsActionType = {
    type:
        | TransferLocationsActionTypes.API_RESPONSE_SUCCESS
        | TransferLocationsActionTypes.API_RESPONSE_ERROR
        | TransferLocationsActionTypes.API_RESPONSE_LIST
        | TransferLocationsActionTypes.API_RESPONSE_SAVE
        | TransferLocationsActionTypes.API_RESPONSE_EDIT
        | TransferLocationsActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type TransferLocations = {
    _id: string;
    locationName: string;
    locationSlug: string;
    isActive: string;
    locationGroup: object;
};

// common success
export const transferLocationApiResponseSuccess = (actionType: string, data: TransferLocations | {}): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const transferLocationApiResponseError = (actionType: string, error: string): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTransferLocations = (): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveTransferLocations = (data: TransferLocations | {}): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editTransferLocations = (data: TransferLocations | {}): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearTransferLocations = (): TransferLocationsActionType => ({
    type: TransferLocationsActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});