import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getTransferLocations as getTransferLocationsApi,
    saveTransferLocations as saveTransferLocationsApi,
    editTransferLocations as editTransferLocationsApi
} from '../../helpers/';

// actions
import { transferLocationApiResponseSuccess, transferLocationApiResponseError } from './actions';

// constants
import { TransferLocationsActionTypes } from './constants';

type TransferLocations = {
    payload: {
        _id: string;
        locationName: string;
        locationSlug: string;
        isActive: string;
        locationGroup: object;
        data: object;
    };
    type: string;
};

function* getTransferLocations(): SagaIterator {
    try {
        const response = yield call(getTransferLocationsApi, {});
        let transferLocationsList = response.data.results;
        yield put(transferLocationApiResponseSuccess(TransferLocationsActionTypes.API_RESPONSE_LIST, transferLocationsList));
    } catch (error: any) {
        yield put(transferLocationApiResponseError(TransferLocationsActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveTransferLocations({ payload: { data } }: TransferLocations): SagaIterator {
    try {
        const response = yield call(saveTransferLocationsApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(transferLocationApiResponseSuccess(TransferLocationsActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(transferLocationApiResponseError(TransferLocationsActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(transferLocationApiResponseError(TransferLocationsActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editTransferLocations({ payload: { data } }: TransferLocations): SagaIterator {
    try {
        const response = yield call(editTransferLocationsApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(transferLocationApiResponseSuccess(TransferLocationsActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(transferLocationApiResponseError(TransferLocationsActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(transferLocationApiResponseError(TransferLocationsActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchTransferLocations() {
    yield takeEvery(TransferLocationsActionTypes.API_RESPONSE_LIST, getTransferLocations);
}

export function* watchSaveTransferLocations() {
    yield takeEvery(TransferLocationsActionTypes.API_RESPONSE_SAVE, saveTransferLocations);
}

export function* watchEditTransferLocations() {
    yield takeEvery(TransferLocationsActionTypes.API_RESPONSE_EDIT, editTransferLocations);
}

function* transferLocationsSaga() {
    yield all([fork(watchTransferLocations), fork(watchSaveTransferLocations), fork(watchEditTransferLocations)]);
}

export default transferLocationsSaga;
