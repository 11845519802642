import { APICore } from './apiCore';

const api = new APICore();

function getTransferLocations(params: {}) {
    const baseUrl = '/locations/';
    return api.get(`${baseUrl}`, params);
}

function saveTransferLocations(params: object) {
    const baseUrl = '/locations/';
    return api.create(`${baseUrl}`, params);
}

function editTransferLocations(params: object) {
    const baseUrl = '/locations/';
    return api.update(`${baseUrl}`, params);
}

export { getTransferLocations, saveTransferLocations, editTransferLocations };