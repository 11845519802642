import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import transferLocationsSaga from './transferLocations/saga';
import locationGroupsSaga from './locationGroups/saga';
import transferRatesSaga from './transferRates/saga';
import tourRatesSaga from './tourRates/saga';
import driverSaga from './drivers/saga';
import userSaga from './users/saga';
import layoutSaga from './layout/saga';

export default function* rootSaga() {
    yield all([authSaga(), transferLocationsSaga(), locationGroupsSaga(), transferRatesSaga(), tourRatesSaga(), driverSaga(), userSaga(), layoutSaga()]);
}
