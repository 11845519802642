import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getTransferRates as getApi,
    saveTransferRates as saveApi,
    editTransferRates as editApi
} from '../../helpers/';

// actions
import { transferRateApiResponseSuccess, transferRateApiResponseError } from './actions';

// constants
import { TransferRatesActionTypes } from './constants';

type TransferRates = {
    payload: {
        _id: string;
        pickupFrom: object;
        destination: object;
        tripType: string;
        passengerCount: number;
        isActive: string;
        rate: number;
        data: object;
    };
    type: string;
};

function* getTransferRates(): SagaIterator {
    try {
        const response = yield call(getApi, {});
        let transferRatesList = response.data.results;
        yield put(transferRateApiResponseSuccess(TransferRatesActionTypes.API_RESPONSE_LIST, transferRatesList));
    } catch (error: any) {
        yield put(transferRateApiResponseError(TransferRatesActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveTransferRates({ payload: { data } }: TransferRates): SagaIterator {
    try {
        const response = yield call(saveApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(transferRateApiResponseSuccess(TransferRatesActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(transferRateApiResponseError(TransferRatesActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(transferRateApiResponseError(TransferRatesActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editTransferRates({ payload: { data } }: TransferRates): SagaIterator {
    try {
        const response = yield call(editApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(transferRateApiResponseSuccess(TransferRatesActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(transferRateApiResponseError(TransferRatesActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(transferRateApiResponseError(TransferRatesActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchTransferRates() {
    yield takeEvery(TransferRatesActionTypes.API_RESPONSE_LIST, getTransferRates);
}

export function* watchSaveTransferRates() {
    yield takeEvery(TransferRatesActionTypes.API_RESPONSE_SAVE, saveTransferRates);
}

export function* watchEditTransferRates() {
    yield takeEvery(TransferRatesActionTypes.API_RESPONSE_EDIT, editTransferRates);
}

function* transferRatesSaga() {
    yield all([fork(watchTransferRates), fork(watchSaveTransferRates), fork(watchEditTransferRates)]);
}

export default transferRatesSaga;
