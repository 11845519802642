import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import {
    getUsers as getApi,
    saveUser as saveApi,
    editUser as editApi
} from '../../helpers/';

// actions
import { userApiResponseSuccess, userApiResponseError } from './actions';

// constants
import { UsersActionTypes } from './constants';

type Users = {
    payload: {
        _id: string;
        fullName: string;
        userName: string;
        userEmail: string;
        password: string;
        isActive: boolean;
        data: object;
    };
    type: string;
};

function* getUsers(): SagaIterator {
    try {
        const response = yield call(getApi, {});
        let userList = response.data.results;
        yield put(userApiResponseSuccess(UsersActionTypes.API_RESPONSE_LIST, userList));
    } catch (error: any) {
        yield put(userApiResponseError(UsersActionTypes.API_RESPONSE_LIST, error));
    }
}

function* saveUser({ payload: { data } }: Users): SagaIterator {
    try {
        const response = yield call(saveApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(userApiResponseSuccess(UsersActionTypes.API_RESPONSE_SAVE, status));
        } else {
            yield put(userApiResponseError(UsersActionTypes.API_RESPONSE_SAVE, status));
        }
    } catch (error: any) {
        yield put(userApiResponseError(UsersActionTypes.API_RESPONSE_SAVE, error));
    }
}

function* editUser({ payload: { data } }: Users): SagaIterator {
    try {
        const response = yield call(editApi, data);
        let status = response.data.status;
        if(status === "Success") {
            yield put(userApiResponseSuccess(UsersActionTypes.API_RESPONSE_EDIT, status));
        } else {
            yield put(userApiResponseError(UsersActionTypes.API_RESPONSE_EDIT, status));
        }
    } catch (error: any) {
        yield put(userApiResponseError(UsersActionTypes.API_RESPONSE_EDIT, error));
    }
}

export function* watchUsers() {
    yield takeEvery(UsersActionTypes.API_RESPONSE_LIST, getUsers);
}

export function* watchSaveUser() {
    yield takeEvery(UsersActionTypes.API_RESPONSE_SAVE, saveUser);
}

export function* watchEditUser() {
    yield takeEvery(UsersActionTypes.API_RESPONSE_EDIT, editUser);
}

function* userSaga() {
    yield all([fork(watchUsers), fork(watchSaveUser), fork(watchEditUser)]);
}

export default userSaga;
