import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import TransferLocations from './transferLocations/reducers';
import LocationGroups from './locationGroups/reducers';
import TransferRates from './transferRates/reducers';
import TourRates from './tourRates/reducers';
import Drivers from './drivers/reducers';
import Users from './users/reducers';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';

export default combineReducers({
    Auth,
    TransferLocations,
    LocationGroups,
    TransferRates,
    TourRates,
    Drivers,
    Users,
    Layout,
    PageTitle,
});
