// constants
import { DriversActionTypes } from './constants';

export type DriversActionType = {
    type:
        | DriversActionTypes.API_RESPONSE_SUCCESS
        | DriversActionTypes.API_RESPONSE_ERROR
        | DriversActionTypes.API_RESPONSE_LIST
        | DriversActionTypes.API_RESPONSE_SAVE
        | DriversActionTypes.API_RESPONSE_EDIT
        | DriversActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type Drivers = {
    _id: string;
    fullName: string;
    driverPhone: string;
    driverEmail: string;
    isSMS: boolean;
    isEmail: boolean;
    isActive: boolean;
};

// common success
export const driverApiResponseSuccess = (actionType: string, data: Drivers | {}): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const driverApiResponseError = (actionType: string, error: string): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDrivers = (): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveDriver = (data: Drivers | {}): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editDriver = (data: Drivers | {}): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearDrivers = (): DriversActionType => ({
    type: DriversActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});