// constants
import { UsersActionTypes } from './constants';

export type UsersActionType = {
    type:
        | UsersActionTypes.API_RESPONSE_SUCCESS
        | UsersActionTypes.API_RESPONSE_ERROR
        | UsersActionTypes.API_RESPONSE_LIST
        | UsersActionTypes.API_RESPONSE_SAVE
        | UsersActionTypes.API_RESPONSE_EDIT
        | UsersActionTypes.API_RESPONSE_CLEAR
    payload: {} | string;
};

type Users = {
    _id: string;
    fullName: string;
    userName: string;
    userEmail: string;
    password: string;
    isActive: boolean;
};

// common success
export const userApiResponseSuccess = (actionType: string, data: Users | {}): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUsers = (): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_LIST,
    payload: {},
});

export const saveUser = (data: Users | {}): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_SAVE,
    payload: { data },
});

export const editUser = (data: Users | {}): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_EDIT,
    payload: { data },
});

export const clearUsers = (): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_CLEAR,
    payload: {},
});